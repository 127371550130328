import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Image,
    useColorModeValue,
} from '@chakra-ui/react';
import { useState, useRef } from 'react';

import logo from '../../assets/logoazul.png';
import logoBranco from '../../assets/logobranco.png';

import Loading from '../../components/Loading';
import { msgErro, msgAtencao } from '../../helpers/funcoes';
import Button from '../../components/diversos/button';
import axiosApi from '../../helpers/axios';
import { useNavigate } from 'react-router';

const LocalizarInscricao = () => {
    let history = useNavigate();
    const [cpf, setCpf] = useState('');
    const [telefone, setTelefone] = useState('');

    const iCPF = useRef<HTMLInputElement>(null);
    const iTelefone = useRef<HTMLInputElement>(null);

    const [loading, setLoading] = useState(false);
    const logoUsado = useColorModeValue(logo, logoBranco);

    const onChangeCpf = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valor = e.target.validity.valid ? e.target.value : cpf;
        setCpf(valor);
    };

    const onChangeTelefone = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTelefone(e.target.value);
    };

    const handleLocalizar = (e: any) => {
        e.preventDefault();
        if (cpf.trim() === '') {
            msgAtencao('Informe o CPF.');
            if (iCPF.current) iCPF.current.focus();
            return;
        }
        if (telefone.trim() === '') {
            msgAtencao('Informe um número de whatsapp.');
            if (iTelefone.current) iTelefone.current.focus();
            return;
        }

        setLoading(true);
        axiosApi
            .post('/localizarinscricao', { telefone, cpf })
            .then((response) => {
                setLoading(false);
                localStorage.setItem(
                    'inscricao',
                    JSON.stringify(response.data.inscricao)
                );
                history('/login');
            })
            .catch((error) => {
                setLoading(false);
                console.log('ERRO', error);

                if (error.response?.data?.error)
                    msgErro(error.response.data.error);
                else
                    msgErro(
                        'Um erro inesperado ocorreu ao localizar as informações do seu plano.'
                    );
            });
    };

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            handleLocalizar(e);
        }
    };

    return (
        <Flex
            minHeight="100vh"
            width="full"
            align="center"
            justifyContent="center"
        >
            <Box
                px={4}
                width="full"
                maxWidth={{ lg: '500px', xl: '500px', base: '90%' }}
                borderRadius={4}
                textAlign="center"
                boxShadow="dark-lg"
            >
                <Box p={4}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Image src={logoUsado} />
                    </Box>
                    <Box my={8} textAlign="left">
                        <form>
                            <FormControl mt={4}>
                                <FormLabel>CPF</FormLabel>
                                <Input
                                    placeholder="Informe o CPF"
                                    maxLength={20}
                                    value={cpf}
                                    onChange={onChangeCpf}
                                    onKeyPress={(e) => handleKeyPress(e)}
                                    autoComplete="off"
                                    type="text"
                                    pattern="[0-9]*"
                                    ref={iCPF}
                                />
                            </FormControl>

                            <FormControl mt={4}>
                                <FormLabel>Whatsapp</FormLabel>
                                <Input
                                    placeholder="Informe o whatsapp"
                                    maxLength={20}
                                    value={telefone}
                                    onChange={onChangeTelefone}
                                    onKeyPress={(e) => handleKeyPress(e)}
                                    autoComplete="off"
                                    type="tel"
                                    ref={iTelefone}
                                />
                            </FormControl>

                            <Button
                                width="full"
                                mt={4}
                                disabled={loading}
                                onClick={handleLocalizar}
                            >
                                {loading && <Loading />}
                                <span>Localizar</span>
                            </Button>
                        </form>
                    </Box>
                </Box>
            </Box>
        </Flex>
    );
};

export default LocalizarInscricao;

