import { Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';
import Header from '../Header';

interface Props {
    children: ReactNode;
}

const Base = ({ children }: Props) => {
    return (
        <Flex
            display="flex"
            flexDirection="column"
            minH="100vh"
            maxW="1200px"
            margin="auto"
            boxShadow="dark-lg"
            px={{
                lg: 0,
                xl: 0,
                base: 2,
            }}
        >
            <Header />
            <Flex
                px={{
                    lg: 3,
                    xl: 3,
                    base: 1,
                }}
                flex="1"
                w="100%"
                mt={2}
                pb={3}
            >
                {children}
            </Flex>
        </Flex>
    );
};

export default Base;
