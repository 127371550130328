import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../helpers/axios';
import { setMensagem } from './mensagem';

const valor = sessionStorage.getItem('associado');

const associado = valor ? JSON.parse(valor) : null;

const initialState = associado
    ? { isLoggedIn: true, associado: associado }
    : { isLoggedIn: false, associado: null };

export interface ILogin {
    inscricao: string;
    cpf: string;
}

export const authLogin = createAsyncThunk<void, ILogin, {}>(
    'auth/login',
    async (dados, thunkApi) => {
        const { inscricao, cpf } = dados;
        let retorno: any;
        await axios
            .post('login', { inscricao, cpf })
            .then((response) => {
                if (response.data.token) {
                    const date = new Date();
                    //7:55h
                    const inc = 1000 * 60 * 60 * 8 - 1000 * 60 * 5;
                    let _date = new Date(date);
                    let secs: number = 0;
                    secs = _date.getTime() + inc;

                    sessionStorage.setItem(
                        'associado',
                        JSON.stringify(response.data)
                    );
                    sessionStorage.setItem('valido', JSON.stringify(secs));
                }
                retorno = response.data;
            })
            .catch((error) => {
                retorno = error.response;
            });
        if (retorno) {
            if (retorno.status && retorno.status !== 200) {
                thunkApi.dispatch(setMensagem(retorno.data));
                return thunkApi.rejectWithValue(retorno.data);
            }
        }
        return retorno;
    }
);

const loginSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            console.log('LOGOUT REDUX');
            sessionStorage.clear();
            window.location.replace('/');
            return {
                ...state,
                isLoggedIn: false,
                associado: null,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(authLogin.fulfilled, (state, action) => {
                console.log('LOGIN OK REDUX');
                state = {
                    ...state,
                    isLoggedIn: true,
                    associado: action.payload,
                };
                if (state.associado.empresa === 0) window.location.replace('/');
                else window.location.replace('/dependentes');
            })
            .addCase(authLogin.rejected, (state, action) => {
                console.log('LOGIN PROBLEMA REDUX');
                state = {
                    ...state,
                    isLoggedIn: false,
                    associado: {},
                };
            });
    },
});

export const { logout } = loginSlice.actions;

export default loginSlice.reducer;
