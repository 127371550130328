import { useEffect, useState, useRef } from 'react';
import {
    useColorModeValue,
    Box,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Text,
    Flex,
    Spacer,
    Divider,
    RadioGroup,
    Stack,
    Radio,
    Icon,
    Grid,
    GridItem,
    FormControl,
    FormLabel,
    Input,
    HStack,
    Heading,
} from '@chakra-ui/react';
import axios from '../../helpers/axios';
import {
    formatarValor,
    dataExibicao,
    msgAtencao,
    msgErro,
    abrirPDF,
    formatarCartao,
    adicionaZero,
    vencido,
} from '../../helpers/funcoes';
import Button from '../../components/diversos/button';
import { FaBarcode, FaCreditCard } from 'react-icons/fa';
import Loading from '../../components/Loading';
import Grade from '../../components/Grade';
import { isMobile } from 'react-device-detect';
import { useAppSelector } from '../../reduxHooks';
import Base from '../../components/base';

const Home = () => {
    const reduxAuth = useAppSelector((state) => state.login);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [data, setData] = useState([]);
    const [idMensalidade, setIdMensalidade] = useState<number[]>([]);
    const [total, setTotal] = useState(0);
    const [radio, setRadio] = useState('1');
    const [display, setDisplay] = useState('');
    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState({
        cartaoNumero: '',
        cartaoNome: '',
        cartaoMes: '',
        cartaoAno: '',
        cartaoCodigo: '',
        email: reduxAuth.associado.email,
    });
    const [erro, setErro] = useState('');

    const cartaoNumero = useRef<HTMLInputElement>(null);
    const cartaoNome = useRef<HTMLInputElement>(null);
    const cartaoMes = useRef<HTMLInputElement>(null);
    const cartaoAno = useRef<HTMLInputElement>(null);
    const cartaoCodigo = useRef<HTMLInputElement>(null);

    const corErro = useColorModeValue('red', 'white');
    const classeVencida = useColorModeValue('vencidas-light', 'vencidas-dark');

    function buscarDados() {
        axios.get('/auth/mensalidade').then((response) => {
            setData(response.data);
        });
    }

    useEffect(() => {
        setDisplay(radio === '1' ? '' : 'none');
    }, [radio]);

    const cols = [
        {
            field: '',
            checkboxSelection: true,
            maxWidth: 35,
        },
        { field: 'id', hide: true },
        { field: 'referencia', headerName: 'Referência', maxWidth: 105 },
        {
            field: 'vencimento',
            headerName: 'Vencimento',
            valueFormatter: (params: any) =>
                dataExibicao(params.data.vencimento),
            minWidth: 110,
            maxWidth: 115,
        },
        {
            field: 'valor',
            type: 'numericColumn',
            headerName: 'Valor',
            valueFormatter: (params: any) => formatarValor(params.data.valor),
            maxWidth: 80,
        },
    ];

    const handlePagar = (e: any) => {
        e.preventDefault();
        if (idMensalidade.length === 0) {
            msgAtencao('Necessário selecionar pelo menos uma mensalidade.');
            return;
        }
        setErro('');
        setRadio('1');
        onOpen();
    };

    const onSelectionChanged = (selectedRows: any) => {
        let ids: number[] = [];
        let tot = 0;
        // eslint-disable-next-line array-callback-return
        selectedRows.map((row: any) => {
            ids.push(row.id);
            tot += row.valor;
        });
        setIdMensalidade(ids);
        setTotal(tot);
    };

    function handleChange(evt: any) {
        let itemAux = item;
        if (evt.target.name === 'cartaoNumero') {
            let valor = formatarCartao(evt.target.value);
            itemAux = { ...itemAux, [evt.target.name]: valor };
        } else itemAux = { ...itemAux, [evt.target.name]: evt.target.value };
        setItem(itemAux);
    }

    function resetar() {
        setItem({
            cartaoNumero: '',
            cartaoNome: '',
            cartaoMes: '',
            cartaoAno: '',
            cartaoCodigo: '',
            email: reduxAuth.associado.email,
        });
    }

    function handleConfirmar() {
        if (loading) return;
        setErro('');
        let janela: any = null;
        if (!isMobile) janela = window.open('', '_blank');
        if (radio === '1') {
            if (item.cartaoNumero.trim() === '') {
                msgAtencao('Número do cartão deve ser informado.');
                if (cartaoNumero.current) cartaoNumero.current.focus();
                return;
            }
            if (item.cartaoNome.trim() === '') {
                msgAtencao('Nome impresso no cartão deve ser informado.');
                if (cartaoNome.current) cartaoNome.current.focus();
                return;
            }
            if (item.cartaoMes.trim() === '') {
                msgAtencao('Validade do cartão deve ser informada.');
                if (cartaoMes.current) cartaoMes.current.focus();
                return;
            }
            if (item.cartaoAno.trim() === '') {
                msgAtencao('Validade do cartão deve ser informada.');
                if (cartaoAno.current) cartaoAno.current.focus();
                return;
            }
            if (item.cartaoCodigo.trim() === '') {
                msgAtencao('Código de segurança deve ser informado.');
                if (cartaoCodigo.current) cartaoCodigo.current.focus();
                return;
            }
            setLoading(true);
            let itemEnviar = {
                ...item,
                cartaoValidade: '',
                valorPago: 0,
                idMensalidade: [0],
            };
            let cartaoValidade;
            if (item.cartaoAno.length === 2)
                cartaoValidade =
                    adicionaZero(item.cartaoMes) +
                    '/20' +
                    adicionaZero(item.cartaoAno);
            else if (item.cartaoAno.length === 4)
                cartaoValidade =
                    adicionaZero(item.cartaoMes) +
                    '/' +
                    adicionaZero(item.cartaoAno);
            else {
                msgAtencao('Ano deve ser informado corretamente.');
                if (cartaoAno.current) cartaoAno.current.focus();
                return;
            }
            itemEnviar.cartaoValidade = cartaoValidade;
            itemEnviar.valorPago = total;
            itemEnviar.idMensalidade = idMensalidade;
            axios
                .post(`/auth/mensalidade`, {
                    ...itemEnviar,
                })
                .then((retorno) => {
                    axios(`/auth/recibo/${retorno.data.id}`, {
                        method: 'GET',
                        responseType: 'blob',
                    })
                        .then((resposta) => {
                            abrirPDF(resposta, janela);
                            onClose();
                            setLoading(false);
                            resetar();
                            buscarDados();
                        })
                        .catch(() => {
                            setLoading(false);
                            setErro(
                                'Não foi possível realizar o download do PDF do recibo.'
                            );
                            msgErro(
                                'Não foi possível realizar o download do PDF do recibo.'
                            );
                            setTimeout(() => {
                                document.location.reload();
                            }, 4000);
                        });
                })
                .catch((error) => {
                    if (!isMobile) janela.close();
                    setLoading(false);
                    setErro('');
                    let mensagem = '';
                    if (error.response.data)
                        mensagem = error.response.data.error;
                    else mensagem = 'Um erro inesperado ocorreu na baixa.';
                    setErro(mensagem);
                    msgErro(mensagem);
                });
        } else {
            setLoading(true);
            axios
                .post(`/auth/boleto/${idMensalidade[0]}`, {
                    email: item.email,
                })
                .then(() => {
                    axios(`/auth/boleto/${idMensalidade[0]}`, {
                        method: 'GET',
                        responseType: 'blob',
                    })
                        .then((resposta) => {
                            abrirPDF(resposta, janela);
                            onClose();
                            setLoading(false);
                        })
                        .catch((error) => {
                            setLoading(false);
                            console.log(error);
                            msgErro(
                                'Não foi possível realizar o download do PDF do boleto.'
                            );
                        });
                })
                .catch((error) => {
                    if (!isMobile) janela.close();
                    setLoading(false);
                    console.log(error);
                    msgErro(
                        'Não foi possível realizar o download do PDF do boleto.'
                    );
                });
        }
    }

    return (
        <Base>
            <Box flex="1" display="flex" flexDir="column">
                <Box mb={2}>
                    <Heading size="md">Emissão de Boleto/Pagamento</Heading>
                </Box>
                <Box>
                    <Button onClick={handlePagar}>Pagar Selecionadas</Button>
                </Box>
                <Grade
                    dados={data}
                    colunas={cols}
                    buscarDados={buscarDados}
                    selectionChanged={onSelectionChanged}
                    grade={{
                        rowSelection: 'multiple',
                        suppressRowClickSelection: true,
                        rowClassRules: {
                            [classeVencida]: function (params: any) {
                                let vencimento = params.data.vencimento;
                                return vencido(vencimento);
                            },
                        },
                    }}
                />
            </Box>
            <Modal
                closeOnOverlayClick={false}
                isOpen={isOpen}
                onClose={onClose}
                isCentered
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Escolha a Forma de Pagamento
                        <Divider />
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Flex>
                            <Box>
                                <Text>Valor total a pagar:</Text>
                            </Box>
                            <Spacer />
                            <Box color={corErro} fontWeight="bold">
                                {total.toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                })}
                            </Box>
                        </Flex>
                        <RadioGroup onChange={setRadio} value={radio}>
                            <Stack direction="row">
                                <Radio value="1">
                                    <Icon as={FaCreditCard} w={16} h={16} />
                                    <Text>Cartão de Crédito</Text>
                                </Radio>
                                {idMensalidade.length === 1 && (
                                    <Radio value="2">
                                        <Icon as={FaBarcode} w={16} h={16} />
                                        <Text>Boleto</Text>
                                    </Radio>
                                )}
                            </Stack>
                        </RadioGroup>
                        <Box as="form" id="frmDados">
                            <Grid
                                templateColumns="repeat(2, 1fr)"
                                w="100%"
                                gap="10px"
                            >
                                <GridItem colSpan={2} display={display}>
                                    <FormControl id="cartaoNumero">
                                        <FormLabel>Número do Cartão</FormLabel>
                                        <Input
                                            placeholder="XXXX-XXXX-XXXX-XXXX"
                                            name="cartaoNumero"
                                            value={item.cartaoNumero}
                                            onChange={(e) => handleChange(e)}
                                            ref={cartaoNumero}
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={2} display={display}>
                                    <FormControl id="cartaoNome">
                                        <FormLabel>
                                            Nome Impresso no Cartão
                                        </FormLabel>
                                        <Input
                                            placeholder="Nome"
                                            name="cartaoNome"
                                            value={item.cartaoNome}
                                            onChange={(e) => handleChange(e)}
                                            ref={cartaoNome}
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem
                                    colSpan={{ lg: 1, xl: 1, base: 2 }}
                                    display={display}
                                >
                                    <FormControl id="validadeCartao">
                                        <FormLabel>
                                            Validade do Cartão
                                        </FormLabel>
                                        <HStack>
                                            <Input
                                                placeholder="MM"
                                                name="cartaoMes"
                                                id="cartaoMes"
                                                width="100px"
                                                value={item.cartaoMes}
                                                onChange={(e) =>
                                                    handleChange(e)
                                                }
                                                ref={cartaoMes}
                                                maxLength={2}
                                            />
                                            <Input
                                                placeholder="AA"
                                                name="cartaoAno"
                                                id="cartaoAno"
                                                width="100px"
                                                value={item.cartaoAno}
                                                onChange={(e) =>
                                                    handleChange(e)
                                                }
                                                ref={cartaoAno}
                                                maxLength={2}
                                            />
                                        </HStack>
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={1} display={display}>
                                    <FormControl id="cartaoCodigo">
                                        <FormLabel>Cód. Segurança</FormLabel>
                                        <Input
                                            placeholder="123"
                                            name="cartaoCodigo"
                                            value={item.cartaoCodigo}
                                            onChange={(e) => handleChange(e)}
                                            ref={cartaoCodigo}
                                            maxLength={3}
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={2}>
                                    <FormControl id="email">
                                        <FormLabel>
                                            {radio === '1'
                                                ? 'E-mail para envio do recibo'
                                                : 'E-mail para envio do boleto'}
                                        </FormLabel>
                                        <Input
                                            placeholder="teste@teste.com.br"
                                            name="email"
                                            value={item.email}
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </FormControl>
                                </GridItem>
                            </Grid>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Box w="100%">
                            {erro !== '' && (
                                <Box mb={1}>
                                    <Text color={corErro} fontWeight="bold">
                                        {erro}
                                    </Text>
                                </Box>
                            )}
                            <Button
                                width="full"
                                onClick={handleConfirmar}
                                isDisabled={loading}
                            >
                                {loading && <Loading />}
                                <Text>{radio === '1' ? 'Pagar' : 'Gerar'}</Text>
                            </Button>
                        </Box>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Base>
    );
};

export default Home;

