import { useState } from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { dataExibicao, formatarValor } from '../../../helpers/funcoes';
import axios from '../../../helpers/axios';
import Grade from '../../../components/Grade';
import { useParams } from 'react-router-dom';
import Base from '../../../components/base';

const RecorrenteParcelas = (props: any) => {
    const [data, setData] = useState([]);

    const { id, numero } = useParams();

    const cols = [
        { field: 'referencia', headerName: 'Referência', maxWidth: 110 },
        {
            field: 'vencimento',
            headerName: 'Vencimento',
            valueFormatter: (params: any) =>
                dataExibicao(params.data.vencimento),
            minWidth: 110,
            maxWidth: 120,
        },
        {
            field: 'valor',
            type: 'numericColumn',
            headerName: 'Valor',
            valueFormatter: (params: any) => formatarValor(params.data.valor),
            maxWidth: 90,
        },
        {
            field: 'pagamento',
            headerName: 'Pagamento',
            valueFormatter: (params: any) =>
                dataExibicao(params.data.pagamento),
            minWidth: 110,
            maxWidth: 120,
        },
        {
            field: 'valorpago',
            type: 'numericColumn',
            headerName: 'Valor Pago',
            valueFormatter: (params: any) =>
                formatarValor(params.data.valorpago),
            minWidth: 120,
        },
    ];

    function buscarDados() {
        axios.get(`/auth/recorrente/${id}/parcelas`).then((response) => {
            console.log('DADOS DAS PARCELAS', response);
            setData(response.data);
        });
    }

    return (
        <Base>
            <Box flex="1" display="flex" flexDir="column">
                <Box>
                    <Heading size="md" mb={2}>
                        Pagamento Recorrente - Parcelas Pagas
                    </Heading>
                    <Heading size="sm" mb={2}>
                        Cartão: {numero}
                    </Heading>
                </Box>
                <Grade
                    dados={data}
                    colunas={cols}
                    buscarDados={buscarDados}
                    grade={{ rowSelection: 'single' }}
                />
            </Box>
        </Base>
    );
};

export default RecorrenteParcelas;
