import { toast } from 'react-toastify';
import { AG_GRID_LOCALE_PT_BR } from './locale.pt-br';
import moment from 'moment';

const tempoToast = 2500;

export const textoGrid = (key: string, defaultValue: string) => {
    try {
        //@ts-ignore
        return AG_GRID_LOCALE_PT_BR[key];
    } catch (err) {
        return defaultValue;
    }
};

export const msgSucesso = (texto: string, onClose?: any) => {
    toast.success(texto, {
        autoClose: tempoToast,
        pauseOnFocusLoss: false,
        onClose: onClose,
        position: 'top-center',
        closeButton: false,
    });
};

export const msgAtencao = (texto: string) => {
    toast.warning(texto, {
        autoClose: tempoToast,
        pauseOnFocusLoss: false,
        position: 'top-center',
        closeButton: false,
    });
};

export const msgErro = (texto: string) => {
    toast.error(texto, {
        autoClose: tempoToast,
        pauseOnFocusLoss: false,
        position: 'top-center',
        closeButton: false,
    });
};

export function formatarValor(currency: number) {
    return currency.toLocaleString('pt-br', { minimumFractionDigits: 2 });
}

export function dataExibicao(data: Date) {
    if (!data) return '';
    //Converte para dd/mm/yyyy
    return moment(data).format('DD/MM/YYYY');
}

export function dataBancoDados(data: Date) {
    //Converte para yyyy-mm-dd
    return moment(data).format('YYYY-MM-DD');
}

export function vencido(data: Date) {
    let now = moment();
    let resultado = moment(data) < now;
    return resultado;
}

export function abrirPDF(dados: any, janela?: any) {
    const file = new Blob([dados.data], {
        type: 'application/pdf',
    });
    const fileURL = URL.createObjectURL(file);
    if (janela) janela.location.href = fileURL;
    else {
        //@ts-ignore
        window.open(fileURL, '_blank').focus();
    }
}

export function somenteNumero(texto: string) {
    if (!texto) return '';
    let r = texto.replace(/\D/g, '');
    if (!r) return '';
    r = r.replace(/^0/, '');
    return r;
}

export function telefoneFormatter(params: any) {
    if (!params.value || params.value === '') return '';
    let r = params.value.replace(/\D/g, '');
    r = r.replace(/^0/, '');
    if (r.length > 10) {
        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (r.length > 5) {
        r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3');
    }
    return r;
}

export function formatarCartao(numero: string) {
    let ccNumString = somenteNumero(numero);
    let block1 = '';
    let block2 = '';
    let block3 = '';
    let block4 = '';
    let formatted = '';

    // all support card types have a 4 digit firt block
    block1 = ccNumString.substring(0, 4);
    if (block1.length === 4) {
        block1 = block1 + ' ';
    }

    block2 = ccNumString.substring(4, 8);
    if (block2.length === 4) {
        block2 = block2 + ' ';
    }
    block3 = ccNumString.substring(8, 12);
    if (block3.length === 4) {
        block3 = block3 + ' ';
    }
    block4 = ccNumString.substring(12, 16);

    formatted = block1 + block2 + block3 + block4;
    return formatted;
}

export function adicionaZero(numero: string) {
    if (numero === '') return;
    if (parseInt(numero) <= 9) return '0' + parseInt(numero).toString();
    else return numero;
}

export const chaveValida = () => {
    const valor = sessionStorage.getItem('valido');
    if (!valor) return false;
    const valido = JSON.parse(valor);
    const dataValido = new Date(valido);
    const dataAtual = new Date();
    const resultado = dataValido.getTime() > dataAtual.getTime();
    return resultado;
};
