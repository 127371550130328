import {
    Box,
    Image,
    useColorModeValue,
    Heading,
    useDisclosure,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerBody,
} from '@chakra-ui/react';
import NavLink from '../NavLink';
import logo from '../../assets/logoazul.png';
import logoBranco from '../../assets/logobranco.png';
import ThemeSelector from '../themeSelector';
import Button from '../diversos/button';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useAppDispatch, useAppSelector } from '../../reduxHooks';
import { logout } from '../../redux/reducers/login';

const Header = () => {
    const dispatch = useAppDispatch();
    const reduxAuth = useAppSelector((state) => state.login);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const logoUsado = useColorModeValue(logo, logoBranco);
    const sombra = useColorModeValue('lg', 'dark-lg');
    const bg = useColorModeValue('white', 'gray.800');
    const bgMenu = useColorModeValue('gray.50', 'gray.700');

    const sair = () => {
        console.log('CLICOU PARA SAIR');
        dispatch(logout());
    };

    const menuGrande = () => {
        return (
            <Box
                display={{ lg: 'flex', xl: 'flex', base: 'none' }}
                backgroundColor={bgMenu}
                mx={{
                    lg: -3,
                    xl: -3,
                    base: -1,
                }}
                px={{
                    lg: 3,
                    xl: 3,
                    base: 1,
                }}
            >
                {reduxAuth.associado.empresa !== 0 ? (
                    <>
                        <NavLink to="/dependentes" onClick={onClose}>
                            Dependentes
                        </NavLink>
                    </>
                ) : (
                    <>
                        <NavLink to="/">Emissão de Boletos/Pagamentos</NavLink>
                        <NavLink to="/dependentes" onClick={onClose}>
                            Dependentes
                        </NavLink>
                        <NavLink
                            to="/recorrente"
                            tooltip="Cadastrar cartão para recebimento automático"
                        >
                            Pagamento Recorrente
                        </NavLink>
                        <NavLink to="/extrato">Mensalidades Pagas</NavLink>
                        <NavLink to="/declaracao">
                            Declaração de Quitação
                        </NavLink>
                    </>
                )}

                <NavLink to="#" onClick={sair}>
                    Sair
                </NavLink>
            </Box>
        );
    };

    const menuCompacto = () => {
        return (
            <Drawer
                placement="left"
                onClose={onClose}
                isOpen={isOpen}
                //@ts-ignore
                display={{ lg: 'none', xl: 'none' }}
            >
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerBody>
                            {reduxAuth.associado.empresa !== 0 ? (
                                <>
                                    <NavLink
                                        to="/dependentes"
                                        onClick={onClose}
                                    >
                                        Dependentes
                                    </NavLink>
                                    <NavLink to="/guias" onClick={onClose}>
                                        Guias
                                    </NavLink>
                                </>
                            ) : (
                                <>
                                    <NavLink to="/" onClick={onClose}>
                                        Emissão de Boletos/Pagamentos
                                    </NavLink>
                                    <NavLink
                                        to="/dependentes"
                                        onClick={onClose}
                                    >
                                        Dependentes
                                    </NavLink>
                                    <NavLink
                                        to="/recorrente"
                                        onClick={onClose}
                                        tooltip="Cadastrar cartão para recebimento automático"
                                    >
                                        Pagamento Recorrente
                                    </NavLink>
                                    <NavLink to="/extrato" onClick={onClose}>
                                        Mensalidades Pagas
                                    </NavLink>
                                    <NavLink to="/guias" onClick={onClose}>
                                        Guias
                                    </NavLink>
                                    <NavLink to="/declaracao" onClick={onClose}>
                                        Declaração de Quitação
                                    </NavLink>
                                </>
                            )}

                            <NavLink to="#" onClick={sair}>
                                Sair
                            </NavLink>
                            <Box>
                                <ThemeSelector mt={2} />
                            </Box>
                        </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        );
    };

    return (
        <Box
            boxShadow={sombra}
            borderRadius={4}
            px={{
                lg: 3,
                xl: 3,
                base: 1,
            }}
            position={{ lg: 'sticky', xl: 'sticky', base: undefined }}
            top="0"
            zIndex={999}
            backgroundColor={bg}
            mb={3}
        >
            {reduxAuth.isLoggedIn ? (
                <>
                    <Box
                        p="3"
                        display="flex"
                        justifyContent={{
                            lg: 'left',
                            xl: 'left',
                            base: 'center',
                        }}
                    >
                        <Image src={logoUsado} />
                        <Box
                            w="100%"
                            flexDir="row-reverse"
                            display={{
                                lg: 'flex',
                                xl: 'flex',
                                base: 'none',
                            }}
                        >
                            <ThemeSelector />
                        </Box>
                    </Box>
                    {menuGrande()}
                    {menuCompacto()}

                    <Box mb={2} mt={2}>
                        <Box
                            display="flex"
                            flexDir={{ lg: 'row', base: 'column' }}
                            w="100%"
                            mb={2}
                        >
                            <Box w="150px">
                                <Heading size="sm">
                                    Contrato: {reduxAuth.associado.inscricao}
                                </Heading>
                            </Box>
                            <Box flex="1">
                                <Heading size="sm">
                                    Nome: {reduxAuth.associado.nome}
                                </Heading>
                            </Box>
                        </Box>
                    </Box>
                    <Button
                        onClick={onOpen}
                        display={{ lg: 'none', xl: 'none' }}
                        mb={2}
                    >
                        <HamburgerIcon w={6} h={6} />
                    </Button>
                </>
            ) : null}
        </Box>
    );
};

export default Header;
