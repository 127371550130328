import { configureStore } from '@reduxjs/toolkit';
import login from './redux/reducers/login';
import mensagem from './redux/reducers/mensagem';

const store = configureStore({
    devTools: process.env.REACT_APP_NODE_ENV === 'development',
    reducer: {
        mensagem: mensagem,
        login: login,
    },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
