import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Image,
    useColorModeValue,
    Text,
    Spacer,
} from '@chakra-ui/react';
import { useState, useEffect, useRef } from 'react';

import logo from '../../assets/logoazul.png';
import logoBranco from '../../assets/logobranco.png';
import ThemeSelector from '../../components/themeSelector';
import Loading from '../../components/Loading';
import { msgErro, msgAtencao } from '../../helpers/funcoes';
import Button from '../../components/diversos/button';
import { useAppDispatch, useAppSelector } from '../../reduxHooks';
import { clearMensagem } from '../../redux/reducers/mensagem';
import { authLogin } from '../../redux/reducers/login';

const Login = () => {
    const dispatch = useAppDispatch();
    const reduxMessage = useAppSelector((state) => state.mensagem);

    const [inscricao, setInscricao] = useState('');
    const [cpf, setCpf] = useState('');

    const iInscricao = useRef<HTMLInputElement>(null);
    const iCPF = useRef<HTMLInputElement>(null);

    useEffect(() => {
        let associado = localStorage.getItem('inscricao');
        if (associado) setInscricao(associado);
    }, []);

    const [loading, setLoading] = useState(false);
    const logoUsado = useColorModeValue(logo, logoBranco);
    const onChangeInscricao = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInscricao(e.target.value);
    };

    const onChangeCpf = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valor = e.target.validity.valid ? e.target.value : cpf;
        setCpf(valor);
    };

    useEffect(() => {
        if (reduxMessage.mensagem && reduxMessage.mensagem !== '') {
            msgErro(reduxMessage.mensagem);
            dispatch(clearMensagem());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reduxMessage.mensagem]);

    const handleLogin = (e: any) => {
        e.preventDefault();
        if (inscricao.trim() === '') {
            msgAtencao('Informe a Inscrição ou nome do titular.');
            if (iInscricao.current) iInscricao.current.focus();
            return;
        }
        if (cpf.trim() === '') {
            msgAtencao('Informe o CPF.');
            if (iCPF.current) iCPF.current.focus();
            return;
        }

        setLoading(true);
        try {
            dispatch(authLogin({ inscricao, cpf }));
        } finally {
            setLoading(false);
        }
    };

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            handleLogin(e);
        }
    };

    return (
        <Flex
            minHeight="100vh"
            width="full"
            align="center"
            justifyContent="center"
        >
            <Box
                px={4}
                width="full"
                maxWidth={{ lg: '500px', xl: '500px', base: '90%' }}
                borderRadius={4}
                textAlign="center"
                boxShadow="dark-lg"
            >
                <Box p={4}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Image src={logoUsado} />
                    </Box>
                    <Box my={8} textAlign="left">
                        <form>
                            <FormControl>
                                <FormLabel>
                                    Inscrição ou Nome do Titular
                                </FormLabel>
                                <Input
                                    type="text"
                                    placeholder="Informe a Inscrição ou nome do titular"
                                    value={inscricao}
                                    onChange={onChangeInscricao}
                                    ref={iInscricao}
                                />
                            </FormControl>

                            <FormControl mt={4}>
                                <FormLabel>CPF</FormLabel>
                                <Input
                                    placeholder="Informe o CPF"
                                    maxLength={20}
                                    value={cpf}
                                    onChange={onChangeCpf}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    autoComplete="off"
                                    type="text"
                                    pattern="[0-9]*"
                                    ref={iCPF}
                                />
                            </FormControl>

                            <Button
                                width="full"
                                mt={4}
                                disabled={loading}
                                onClick={handleLogin}
                            >
                                {loading && <Loading />}
                                <span>Entrar</span>
                            </Button>
                            <Box
                                marginTop={4}
                                display={'flex'}
                                flexDir={'row'}
                                alignItems={'center'}
                                alignContent={'space-between'}
                            >
                                <a href="/inscricao">
                                    <Text
                                        fontSize="md"
                                        fontWeight={'bold'}
                                        ml={2}
                                    >
                                        Não sabe a inscrição? Clique aqui
                                    </Text>
                                </a>
                                <Spacer />
                                <ThemeSelector />
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Box>
        </Flex>
    );
};

export default Login;
