import { useState, useRef, useEffect } from 'react';
import {
    Heading,
    Flex,
    Box,
    Grid,
    GridItem,
    FormControl,
    FormLabel,
    Input,
    HStack,
    Text,
    useColorModeValue,
    Image,
} from '@chakra-ui/react';
import { useParams } from 'react-router';
import {
    formatarCartao,
    msgAtencao,
    adicionaZero,
    msgErro,
    msgSucesso,
} from '../../helpers/funcoes';
import Button from '../../components/diversos/button';
import axios from '../../helpers/axios';
import Loading from '../../components/Loading';
import logo from '../../assets/logoazul.png';
import logoBranco from '../../assets/logobranco.png';

const RecorrenteNovoAssociado = () => {
    const { id } = useParams();
    const [inscricao, setInscricao] = useState<number>(0);
    const [erro, setErro] = useState<string>('');
    const [item, setItem] = useState({
        cartaoNome: '',
        cartaoCPF: '',
        cartaoNumero: '',
        cartaoMes: '',
        cartaoAno: '',
        cartaoCodigo: '',
        celular: '',
        email: '',
    });
    const [loading, setLoading] = useState(false);
    const logoUsado = useColorModeValue(logo, logoBranco);

    const cartaoNome = useRef<HTMLInputElement>(null);
    const cartaoCPF = useRef<HTMLInputElement>(null);
    const cartaoNumero = useRef<HTMLInputElement>(null);
    const cartaoMes = useRef<HTMLInputElement>(null);
    const cartaoAno = useRef<HTMLInputElement>(null);
    const cartaoCodigo = useRef<HTMLInputElement>(null);

    const corErro = useColorModeValue('red', 'white');

    useEffect(() => {
        axios
            .get(`/recorrentenovoassociado/${id}`)
            .then((response) => {
                setInscricao(response.data.inscricao);
            })
            .catch((error) => {
                console.log('ERRO', error);
                if (error.response.data) {
                    setErro(error.response.data.error);
                    msgErro(error.response.data.error);
                } else {
                    setErro(
                        'Um erro não previsto ocorreu ao localizar as informações.'
                    );
                    msgErro(
                        'Um erro não previsto ocorreu ao localizar as informações.'
                    );
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
        let itemAux = item;
        if (evt.target.name === 'cartaoNumero') {
            let valor = formatarCartao(evt.target.value);
            itemAux = { ...itemAux, [evt.target.name]: valor };
        } else itemAux = { ...itemAux, [evt.target.name]: evt.target.value };
        setItem(itemAux);
    }

    function handleConfirmar() {
        if (loading) return;
        setErro('');
        if (item.cartaoNome.trim() === '') {
            msgAtencao('Nome impresso no cartão deve ser informado.');
            if (cartaoNome.current) cartaoNome.current.focus();
            return;
        }
        if (item.cartaoCPF.trim() === '') {
            msgAtencao('CPF do titular do cartão deve ser informado.');
            if (cartaoCPF.current) cartaoCPF.current.focus();
            return;
        }
        if (item.cartaoNumero.trim() === '') {
            msgAtencao('Número do cartão deve ser informado.');
            if (cartaoNumero.current) cartaoNumero.current.focus();
            return;
        }
        if (item.cartaoMes.trim() === '') {
            msgAtencao('Validade do cartão deve ser informada.');
            if (cartaoMes.current) cartaoMes.current.focus();
            return;
        }
        if (item.cartaoAno.trim() === '') {
            msgAtencao('Validade do cartão deve ser informada.');
            if (cartaoAno.current) cartaoAno.current.focus();
            return;
        }
        if (item.cartaoCodigo.trim() === '') {
            msgAtencao('Código de segurança deve ser informado.');
            if (cartaoCodigo.current) cartaoCodigo.current.focus();
            return;
        }
        setLoading(true);
        let itemEnviar = { ...item, cartaoValidade: '', inscricao: inscricao };
        itemEnviar.cartaoValidade =
            adicionaZero(item.cartaoMes) + '/20' + adicionaZero(item.cartaoAno);
        axios
            .post(`/recorrentenovoassociado/${id}`, {
                ...itemEnviar,
            })
            .then(() => {
                msgSucesso('Os dados do cartão foram cadastrados com sucesso.');
                setTimeout(() => {
                    window.location.reload();
                }, 3500);
            })
            .catch((error) => {
                setLoading(false);
                setErro('');
                let mensagem = '';
                if (error.response.data) mensagem = error.response.data.error;
                else
                    mensagem =
                        'Um erro inesperado ocorreu no cadastro do cartão.';
                setErro(mensagem);
                msgErro(mensagem);
            });
    }
    return (
        <Flex
            display="flex"
            flexDirection="column"
            minH="100vh"
            maxW="1200px"
            margin="auto"
            boxShadow="dark-lg"
            px={{
                lg: 0,
                xl: 0,
                base: 2,
            }}
        >
            <Flex
                px={{
                    lg: 3,
                    xl: 3,
                    base: 1,
                }}
                flex="1"
                w="100%"
                mt={2}
                pb={3}
            >
                <Box flex="1" display="flex" flexDir="column">
                    <Box>
                        <Box display="flex" mb={4}>
                            <Image src={logoUsado} />
                        </Box>
                        <Heading size="md" mb={2}>
                            Pagamento Recorrente - Novo Associado
                            {inscricao > 0 && ` - Inscrição: ${inscricao}`}
                        </Heading>
                    </Box>
                    {inscricao === 0 ? (
                        <Box mt={1} mb={1} flex="1">
                            <Text>{erro}</Text>
                        </Box>
                    ) : (
                        <Box mt={1} mb={1} flex="1">
                            <Box as="form" id="frmDados" maxW="600px">
                                <Grid
                                    templateColumns="repeat(2, 1fr)"
                                    w="100%"
                                    gap="10px"
                                >
                                    <GridItem colSpan={2}>
                                        <FormControl id="cartaoNome">
                                            <FormLabel>
                                                Nome Impresso no Cartão
                                            </FormLabel>
                                            <Input
                                                placeholder="Nome do Cartão"
                                                name="cartaoNome"
                                                value={item.cartaoNome}
                                                onChange={(e) =>
                                                    handleChange(e)
                                                }
                                                ref={cartaoNome}
                                            />
                                        </FormControl>
                                    </GridItem>
                                    <GridItem
                                        colSpan={{ lg: 1, xl: 1, base: 2 }}
                                    >
                                        <FormControl id="cartaoCPF">
                                            <FormLabel>
                                                CPF do Titular do Cartão
                                            </FormLabel>
                                            <Input
                                                placeholder="123.456.789-00"
                                                name="cartaoCPF"
                                                value={item.cartaoCPF}
                                                onChange={(e) =>
                                                    handleChange(e)
                                                }
                                                ref={cartaoCPF}
                                            />
                                        </FormControl>
                                    </GridItem>
                                    <GridItem
                                        colSpan={{ lg: 1, xl: 1, base: 2 }}
                                    >
                                        <FormControl id="cartaoNumero">
                                            <FormLabel>
                                                Número do Cartão
                                            </FormLabel>
                                            <Input
                                                placeholder="XXXX-XXXX-XXXX-XXXX"
                                                name="cartaoNumero"
                                                value={item.cartaoNumero}
                                                onChange={(e) =>
                                                    handleChange(e)
                                                }
                                                ref={cartaoNumero}
                                            />
                                        </FormControl>
                                    </GridItem>
                                    <GridItem
                                        colSpan={{ lg: 1, xl: 1, base: 2 }}
                                    >
                                        <FormControl id="validadeCartao">
                                            <FormLabel>
                                                Validade do Cartão
                                            </FormLabel>
                                            <HStack>
                                                <Input
                                                    placeholder="MM"
                                                    name="cartaoMes"
                                                    id="cartaoMes"
                                                    width="100px"
                                                    value={item.cartaoMes}
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                    maxLength={2}
                                                    ref={cartaoMes}
                                                />
                                                <Input
                                                    placeholder="AA"
                                                    name="cartaoAno"
                                                    id="cartaoAno"
                                                    width="100px"
                                                    value={item.cartaoAno}
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                    maxLength={2}
                                                    ref={cartaoAno}
                                                />
                                            </HStack>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem
                                        colSpan={{ lg: 1, xl: 1, base: 2 }}
                                    >
                                        <FormControl id="cartaoCodigo">
                                            <FormLabel>
                                                Cód. Segurança
                                            </FormLabel>
                                            <Input
                                                placeholder="123"
                                                name="cartaoCodigo"
                                                value={item.cartaoCodigo}
                                                onChange={(e) =>
                                                    handleChange(e)
                                                }
                                                maxLength={3}
                                                ref={cartaoCodigo}
                                            />
                                        </FormControl>
                                    </GridItem>
                                    <GridItem
                                        colSpan={{ lg: 1, xl: 1, base: 2 }}
                                    >
                                        <FormControl id="email">
                                            <FormLabel>
                                                E-mail para envio do recibo
                                            </FormLabel>
                                            <Input
                                                placeholder="teste@teste.com.br"
                                                name="email"
                                                value={item.email}
                                                onChange={(e) =>
                                                    handleChange(e)
                                                }
                                            />
                                        </FormControl>
                                    </GridItem>
                                    <GridItem
                                        colSpan={{ lg: 1, xl: 1, base: 2 }}
                                    >
                                        <FormControl id="celular">
                                            <FormLabel>Celular</FormLabel>
                                            <Input
                                                placeholder="(XX) XXXXX-XXXX"
                                                name="celular"
                                                value={item.celular}
                                                onChange={(e) =>
                                                    handleChange(e)
                                                }
                                            />
                                        </FormControl>
                                    </GridItem>
                                </Grid>
                                {erro !== '' && (
                                    <Box my={1}>
                                        <Text color={corErro} fontWeight="bold">
                                            {erro}
                                        </Text>
                                    </Box>
                                )}
                                <Box display="flex" mt={4}>
                                    <Button
                                        mr={4}
                                        minW="100px"
                                        onClick={handleConfirmar}
                                    >
                                        Salvar
                                        {loading && <Loading />}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Flex>
        </Flex>
    );
};

export default RecorrenteNovoAssociado;
