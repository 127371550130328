import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    useColorModeValue,
} from '@chakra-ui/react';
import { useRef } from 'react';
import Button from './button';
import PropTypes from 'prop-types';

const ConfirmarExclusao = (props) => {
    const onClose = () => props.toggleOpen(false);
    const cancelRef = useRef();
    const onDelete = () => {
        props.apagar();
        props.toggleOpen(false);
    };
    const bgBox = useColorModeValue('white', 'gray.800');

    return (
        <AlertDialog
            isOpen={props.isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            closeOnOverlayClick={false}
            onEsc={onClose}
            isCentered
        >
            <AlertDialogOverlay>
                <AlertDialogContent bg={bgBox}>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {props.titulo}
                    </AlertDialogHeader>

                    <AlertDialogBody>{props.texto}</AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Cancelar
                        </Button>
                        <Button colorScheme="red" onClick={onDelete} ml={3}>
                            Confirmar
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

ConfirmarExclusao.propTypes = {
    titulo: PropTypes.string,
    texto: PropTypes.string,
    apagar: PropTypes.func,
    isOpen: PropTypes.bool,
    toggleOpen: PropTypes.func,
};

export default ConfirmarExclusao;
