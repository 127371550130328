import { Box, Heading } from '@chakra-ui/layout';
import Loading from '../Loading';

const customLoading = () => {
    return (
        <Box className="ag-custom-loading-cell">
            <Heading size="md">
                Carregando...
                <Loading />
            </Heading>
        </Box>
    );
};

export default customLoading;
