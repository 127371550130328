import { Routes, Route } from 'react-router-dom';
import { chaveValida } from './helpers/funcoes';
import Declaracao from './pages/Declaracao';
import Dependentes from './pages/Dependentes';
import Extrato from './pages/Extrato';
import Guias from './pages/Guias';
import GuiasNovo from './pages/Guias/Novo';
import Home from './pages/Home';
import LocalizarInscricao from './pages/LocalizarInscricao';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Recorrente from './pages/Recorrente';
import RecorrenteNovo from './pages/Recorrente/Novo';
import RecorrenteParcelas from './pages/Recorrente/Parcelas';
import RecorrenteNovoAssociado from './pages/RecorrenteNovoAssociado';
import { useAppSelector } from './reduxHooks';

const Rotas = () => {
    const reduxAuth = useAppSelector((state) => state.login);

    return !sessionStorage.getItem('associado') || !chaveValida() ? (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/inscricao" element={<LocalizarInscricao />} />
            <Route
                path="/recorrentenovo/:id"
                element={<RecorrenteNovoAssociado />}
            />
            <Route path="*" element={<Login />} />
        </Routes>
    ) : (
        <Routes>
            {reduxAuth.associado.empresa === 0 ? (
                <>
                    <Route path="/" element={<Home />} />
                    <Route path="/dependentes" element={<Dependentes />} />
                    <Route path="/extrato" element={<Extrato />} />
                    <Route path="/declaracao" element={<Declaracao />} />
                    <Route path="/guias" element={<Guias />} />
                    <Route path="/guias/novo" element={<GuiasNovo />} />
                    <Route path="/recorrente" element={<Recorrente />} />
                    <Route
                        path="/recorrente/novo"
                        element={<RecorrenteNovo />}
                    />
                    <Route
                        path="/recorrente/parcelas/:id/:numero"
                        element={<RecorrenteParcelas />}
                    />
                    <Route element={<NotFound />} />
                </>
            ) : (
                <>
                    <Route path="/" element={<Dependentes />} />
                    <Route path="/dependentes" element={<Dependentes />} />
                    <Route path="/guias" element={<Guias />} />
                    <Route path="/guias/novo" element={<GuiasNovo />} />
                    <Route element={<NotFound />} />
                </>
            )}
        </Routes>
    );
};

export default Rotas;
