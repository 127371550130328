import { useState, useRef } from 'react';
import {
    Heading,
    Box,
    Tooltip,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Divider,
    Text,
    Grid,
    GridItem,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
} from '@chakra-ui/react';
import {
    dataExibicao,
    msgAtencao,
    msgErro,
    msgSucesso,
} from '../../helpers/funcoes';
import axios from '../../helpers/axios';
import Grade from '../../components/Grade';
import Button from '../../components/diversos/button';
import Loading from '../../components/Loading';
import { useAppSelector } from '../../reduxHooks';
import Base from '../../components/base';

const Dependentes = () => {
    const reduxAuth = useAppSelector((state) => state.login);
    const [data, setData] = useState([]);
    const [idDependente, setIdDependente] = useState<number[]>([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [item, setItem] = useState({
        celular: '',
        email: reduxAuth.associado.email,
    });
    const [erro, setErro] = useState('');
    const corErro = useColorModeValue('red', 'white');
    const [loading, setLoading] = useState(false);

    const email = useRef<HTMLInputElement>(null);

    const cols = [
        {
            field: '',
            checkboxSelection: true,
            maxWidth: 35,
        },
        { field: 'id', hide: true },
        { field: 'nome', headerName: 'Nome', minWidth: 250 },
        {
            field: 'nascimento',
            headerName: 'Nascimento',
            valueFormatter: (params: any) =>
                dataExibicao(params.data.nascimento),
            minWidth: 110,
            maxWidth: 120,
        },
        { field: 'parentesco', headerName: 'Parentesco', minWidth: 150 },
        {
            field: 'carencia',
            headerName: 'Carência',
            valueFormatter: (params: any) => dataExibicao(params.data.carencia),
            minWidth: 110,
            maxWidth: 120,
        },
    ];

    function buscarDados() {
        axios
            .get(`/auth/dependentes`)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                console.log('ERRO', error);
                msgErro(
                    'Não foi possível localizar as informações dos dependentes.'
                );
            });
    }

    const onSelectionChanged = (selectedRows: any) => {
        let ids: number[] = [];
        // eslint-disable-next-line array-callback-return
        selectedRows.map((row: any) => {
            ids.push(row.id);
        });
        setIdDependente(ids);
    };

    function handleChange(evt: any) {
        let itemAux = item;
        itemAux = { ...itemAux, [evt.target.name]: evt.target.value };
        setItem(itemAux);
    }

    const handleGerar = (e: any) => {
        e.preventDefault();
        if (idDependente.length === 0) {
            msgAtencao('Necessário selecionar pelo menos uma mensalidade.');
            return;
        }
        setErro('');
        onOpen();
    };

    function handleConfirmar() {
        if (loading) return;
        setErro('');

        if (item.email.trim() === '' && item.celular.trim() === '') {
            msgAtencao('Por favor, informe e-mail ou Whats App.');
            if (email.current) email.current.focus();
            return;
        }

        setLoading(true);
        let itemEnviar = { ...item, id: idDependente };

        axios
            .post(`/auth/carteirinha`, {
                ...itemEnviar,
            })
            .then(() => {
                setLoading(false);
                setErro('');
                msgSucesso(
                    'A solicitação de carteirinha foi realizada com sucesso.'
                );
                setTimeout(() => {
                    document.location.reload();
                }, 4000);
            })
            .catch((error) => {
                setLoading(false);
                setErro('');
                let mensagem = '';
                if (error.response.data) mensagem = error.response.data.error;
                else mensagem = 'Um erro inesperado ocorreu na solicitação.';
                setErro(mensagem);
                msgErro(mensagem);
            });
    }

    return (
        <Base>
            <Box flex="1" display="flex" flexDir="column">
                <Box mb={2}>
                    <Heading size="md">Dependentes</Heading>
                </Box>
                <Box mb={2}>
                    <Tooltip
                        hasArrow
                        label="Gera carteirinha digital para as pessoas selecionadas"
                    >
                        <span>
                            <Button
                                mr={2}
                                minW="150px"
                                onClick={handleGerar}
                                isDisabled={idDependente.length === 0}
                            >
                                Carteirinha
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
                <Grade
                    dados={data}
                    colunas={cols}
                    buscarDados={buscarDados}
                    grade={{
                        rowSelection: 'multiple',
                        suppressRowClickSelection: true,
                    }}
                    estilo={{
                        height: '500px',
                        width: '100%',
                    }}
                    selectionChanged={onSelectionChanged}
                />
                <Modal
                    closeOnOverlayClick={false}
                    isOpen={isOpen}
                    onClose={onClose}
                    isCentered
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            Informe os dados de contato
                            <Divider />
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <Box as="form" id="frmDados">
                                <Grid
                                    templateColumns="repeat(2, 1fr)"
                                    w="100%"
                                    gap="10px"
                                >
                                    <GridItem colSpan={2}>
                                        <FormControl id="email">
                                            <FormLabel>
                                                E-mail para envio da carteirinha
                                            </FormLabel>
                                            <Input
                                                placeholder="teste@teste.com.br"
                                                name="email"
                                                value={item.email}
                                                onChange={(e) =>
                                                    handleChange(e)
                                                }
                                                ref={email}
                                            />
                                        </FormControl>
                                        <FormControl id="celular">
                                            <FormLabel>
                                                WhatsApp para envio da
                                                carteirinha
                                            </FormLabel>
                                            <Input
                                                placeholder="(XX) XXXXX-XXXX"
                                                name="celular"
                                                value={item.celular}
                                                onChange={(e) =>
                                                    handleChange(e)
                                                }
                                            />
                                        </FormControl>
                                    </GridItem>
                                </Grid>
                            </Box>
                        </ModalBody>
                        <ModalFooter>
                            <Box w="100%">
                                {erro !== '' && (
                                    <Box mb={1}>
                                        <Text color={corErro} fontWeight="bold">
                                            {erro}
                                        </Text>
                                    </Box>
                                )}
                                <Button
                                    width="full"
                                    onClick={handleConfirmar}
                                    isDisabled={loading}
                                >
                                    {loading && <Loading />}
                                    <Text>Gerar</Text>
                                </Button>
                            </Box>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        </Base>
    );
};

export default Dependentes;
