import {
    Box,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    Input,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Button from '../../../components/diversos/button';
import Loading from '../../../components/Loading';
import axios from '../../../helpers/axios';
import { msgErro, abrirPDF, msgAtencao } from '../../../helpers/funcoes';
import { useNavigate } from 'react-router';
import Grade from '../../../components/Grade';
import { useAppSelector } from '../../../reduxHooks';
import Base from '../../../components/base';

const GuiasNovo = () => {
    let history = useNavigate();
    const reduxAuth = useAppSelector((state) => state.login);
    const [dataConveniados, setDataConveniados] = useState([]);
    const [dataPacientes, setDataPacientes] = useState([]);
    const [paciente, setPaciente] = useState(0);
    const [conveniado, setConveniado] = useState({
        idConveniado: 0,
        idEspecialidade: 0,
    });
    const [item, setItem] = useState({
        conveniado: '',
        especialidade: '',
        cidade: '',
        email: reduxAuth.associado.email,
        celular: '',
    });
    const [loading, setLoading] = useState(false);
    const [erro, setErro] = useState('');
    function buscarDados() {
        if (
            item.conveniado === '' &&
            item.especialidade === '' &&
            item.cidade === ''
        ) {
            msgErro('Por favor, pelo menos um filtro deve ser informado.');
            return null;
        }
        setLoading(true);
        let url = '/auth/guias/conveniados?';
        let separador = '';

        if (item.conveniado !== '') {
            url += `nome=${item.conveniado}`;
            separador = '&';
        }

        if (item.especialidade !== '') {
            url += `${separador}especialidade=${item.especialidade}`;
            separador = '&';
        }

        if (item.cidade !== '') url += `${separador}cidade=${item.cidade}`;

        axios
            .get(url)
            .then((retorno) => {
                setLoading(false);
                setDataConveniados(retorno.data);
            })
            .catch((error) => {
                setLoading(false);
                let mensagem = '';
                if (error.response.data) mensagem = error.response.data.error;
                else mensagem = 'Não foi possível localizar os conveniados.';
                msgErro(mensagem);
            });
    }

    function handleChange(evt: any) {
        let itemAux = item;
        itemAux = { ...itemAux, [evt.target.name]: evt.target.value };
        setItem(itemAux);
    }

    const corErro = useColorModeValue('red', 'white');

    const colsConveniados = [
        {
            field: 'nome',
            headerName: 'Nome',
            minWidth: 200,
        },
        {
            field: 'especialidade',
            headerName: 'Especialidade',
            minWidth: 200,
            //flex: '1',
        },
        {
            field: 'cidade',
            headerName: 'Cidade',
            minWidth: 200,
        },
        {
            field: 'bairro',
            headerName: 'Bairro',
            minWidth: 150,
        },
        {
            field: 'telefone',
            headerName: 'Telefone',
            minWidth: 150,
        },
    ];

    const colsPacientes = [
        {
            field: 'nome',
            headerName: 'Nome',
            minWidth: 300,
        },
    ];

    function handleConfirmar() {
        if (loading) return;
        setErro('');
        if (conveniado.idConveniado === 0) {
            msgAtencao('Por favor, informe o conveniado.');
            return;
        }
        if (paciente === 0) {
            msgAtencao('Por favor, informe o paciente.');
            return;
        }
        setLoading(true);
        axios
            .post('auth/guias', {
                conveniado: conveniado.idConveniado,
                paciente: paciente,
                especialidade: conveniado.idEspecialidade,
            })
            .then((retorno) => {
                let LUrl = `/auth/guias/${retorno.data.id}`;
                let LSeparador = '';
                if (item.email !== '' || item.celular !== '') LUrl += '?';
                if (item.email !== '') {
                    LUrl += `email=${item.email}`;
                    LSeparador = '&';
                }
                if (item.celular !== '') {
                    LUrl += `${LSeparador}celular=${item.celular}`;
                }
                axios(LUrl, {
                    method: 'GET',
                    responseType: 'blob',
                })
                    .then((resposta) => {
                        abrirPDF(resposta);
                        setLoading(false);
                        history('/guias');
                    })
                    .catch((error) => {
                        setLoading(false);
                        let mensagem = '';
                        if (error.response)
                            mensagem = error.response.data.error;
                        else
                            mensagem =
                                'Não foi possível realizar o download do PDF da guia.';
                        msgErro(mensagem);
                    });
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                let mensagem = '';
                if (error.response.data) mensagem = error.response.data.error;
                else mensagem = 'Erro desconhecido ao emitir a guia.';
                msgErro(mensagem);
            });
    }

    useEffect(() => {
        axios
            .get('auth/guias/dependentes')
            .then((dados) => {
                setDataPacientes(dados.data);
            })
            .catch((error) => {
                console.log('ERRO PACIENTE', error);
            });
    }, []);

    const onSelectionConveniados = (selectedRows: any) => {
        setConveniado(
            selectedRows.length === 1
                ? {
                      idConveniado: selectedRows[0].id,
                      idEspecialidade: selectedRows[0].especialidadeid,
                  }
                : {
                      idConveniado: 0,
                      idEspecialidade: 0,
                  }
        );
    };

    const onSelectionPacientes = (selectedRows: any) => {
        setPaciente(selectedRows.length === 1 ? selectedRows[0].id : 0);
    };

    return (
        <Base>
            <Box flex="1" display="flex" flexDir="column">
                <Box mb={2}>
                    <Heading size="md">Guias - Nova Guia</Heading>
                </Box>
                <Grid
                    templateColumns={{
                        lg: 'repeat(3, 1fr)',
                        xl: 'repeat(3, 1fr)',
                        base: 'repeat(1, 1fr)',
                    }}
                    w="100%"
                    gap="10px"
                    mb={2}
                >
                    <GridItem colSpan={1}>
                        <FormLabel>Conveniado</FormLabel>
                        <Input
                            name="conveniado"
                            value={item.conveniado}
                            onChange={(e) => handleChange(e)}
                        />
                    </GridItem>
                    <GridItem colSpan={1}>
                        <FormLabel>Especialidade</FormLabel>
                        <Input
                            name="especialidade"
                            value={item.especialidade}
                            onChange={(e) => handleChange(e)}
                        />
                    </GridItem>
                    <GridItem colSpan={1}>
                        <FormLabel>Cidade</FormLabel>
                        <Input
                            name="cidade"
                            value={item.cidade}
                            onChange={(e) => handleChange(e)}
                        />
                    </GridItem>
                </Grid>
                <Box mb={2}>
                    <Button onClick={buscarDados} isDisabled={loading}>
                        {loading && <Loading />}
                        <span>Pesquisar</span>
                    </Button>
                </Box>
                <Grade
                    dados={dataConveniados}
                    colunas={colsConveniados}
                    buscarDados={null}
                    selectionChanged={onSelectionConveniados}
                    grade={{ rowSelection: 'single' }}
                    flex={false}
                />
                <Grade
                    dados={dataPacientes}
                    colunas={colsPacientes}
                    buscarDados={null}
                    selectionChanged={onSelectionPacientes}
                    grade={{ rowSelection: 'single' }}
                    flex={false}
                />
                <Box mb={2}>
                    <FormControl id="email">
                        <FormLabel>E-mail para envio da guia</FormLabel>
                        <Input
                            placeholder="teste@teste.com.br"
                            name="email"
                            value={item.email}
                            onChange={(e) => handleChange(e)}
                            maxW="300px"
                        />
                    </FormControl>
                    <FormControl id="celular">
                        <FormLabel>WhatsApp para envio da guia</FormLabel>
                        <Input
                            placeholder="(XX) XXXXX-XXXX"
                            name="celular"
                            value={item.celular}
                            onChange={(e) => handleChange(e)}
                            maxW="300px"
                        />
                    </FormControl>
                </Box>
                <Box display="flex" mt={2} mb={4}>
                    <Button mr={4} minW="100px" onClick={handleConfirmar}>
                        Salvar
                        {loading && <Loading />}
                    </Button>
                    <Button
                        onClick={() => {
                            history('/guias');
                        }}
                        minW="100px"
                    >
                        Voltar
                    </Button>
                    {erro !== '' && (
                        <Box mb={1}>
                            <Text color={corErro} fontWeight="bold">
                                {erro}
                            </Text>
                        </Box>
                    )}
                </Box>
            </Box>
        </Base>
    );
};

export default GuiasNovo;
