import { Box, Heading, Text } from '@chakra-ui/react';
import Button from '../../components/diversos/button';
import { dataExibicao, abrirPDF, msgErro } from '../../helpers/funcoes';
import { useState } from 'react';
import axios from '../../helpers/axios';
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router';
import Grade from '../../components/Grade';
import { isMobile } from 'react-device-detect';
import Base from '../../components/base';

const Guias = () => {
    let history = useNavigate();
    const [data, setData] = useState([]);
    const [id, setId] = useState(0);
    const [loading, setLoading] = useState(false);
    var cols = [
        {
            field: 'data',
            headerName: 'Data',
            valueFormatter: (params: any) => dataExibicao(params.data.data),
            minWidth: 110,
        },
        {
            field: 'vencimento',
            headerName: 'Vencimento',
            valueFormatter: (params: any) =>
                dataExibicao(params.data.vencimento),
            minWidth: 110,
        },
        {
            field: 'paciente',
            headerName: 'Paciente',
            minWidth: 220,
        },
        {
            field: 'conveniado',
            headerName: 'Conveniado',
            minWidth: 200,
        },
        {
            field: 'especialidade',
            headerName: 'Especialidade',
            minWidth: 200,
        },
    ];

    function buscarDados() {
        axios.get(`/auth/guias`).then((response) => {
            setData(response.data);
        });
    }

    const onSelectionChanged = (selectedRows: any) => {
        setId(selectedRows.length === 1 ? selectedRows[0].id : 0);
    };

    function handleImprimir() {
        setLoading(true);
        let janela: any = null;
        if (!isMobile) janela = window.open('', '_blank');
        axios(`/auth/guias/${id}`, {
            method: 'GET',
            responseType: 'blob',
        })
            .then((resposta) => {
                abrirPDF(resposta, janela);
                setLoading(false);
            })
            .catch((error) => {
                if (!isMobile) janela.close();
                setLoading(false);
                let mensagem = '';
                if (error.response.data) mensagem = error.response.data.error;
                else
                    mensagem =
                        'Não foi possível realizar o download do PDF da guia.';
                msgErro(mensagem);
            });
    }

    return (
        <Base>
            <Box flex="1" display="flex" flexDir="column">
                <Box mb={2}>
                    <Heading size="md">Guias</Heading>
                </Box>
                <Box mb={2}>
                    <Button
                        onClick={handleImprimir}
                        mr={4}
                        isDisabled={id === 0 || loading}
                    >
                        {loading && <Loading />}
                        <Text>Imprimir</Text>
                    </Button>
                    <Button onClick={() => history('/guias/novo')}>
                        Nova Guia
                    </Button>
                </Box>
                <Grade
                    dados={data}
                    colunas={cols}
                    buscarDados={buscarDados}
                    selectionChanged={onSelectionChanged}
                    grade={{ rowSelection: 'single' }}
                />
            </Box>
        </Base>
    );
};

export default Guias;
