import { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import customLoading from './customLoading';
import customNoRows from './customNoRows';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box } from '@chakra-ui/layout';
import { textoGrid } from '../../helpers/funcoes';
import { ColumnApi, GridApi, AgGridEvent } from 'ag-grid-community';

/*
    dados:              Array com os dados para serem exibidos.
    colunas:            Array dos campos da grid.
    buscarDados:        Função inicial de busca das informações.
    selectionChanged:   Função que recebe as linhas selecionadas para serem trabalhadas no
                        parent da grade.
    grade:              Propriedades específicas da grade que serão necessárias no parent.
    flex:               Propriedade para aumentar ao máximo a grade ou deixar com altura de 500px
*/

const Grade = (props: any) => {
    const [gridApi, setGridApi] = useState<GridApi>();
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
    const [recalculado, setRecalculado] = useState(false);

    const gridTheme = useColorModeValue(
        'ag-theme-alpine',
        'ag-theme-alpine-dark'
    );

    const recalcularColunas = () => {
        if (props.dados.length && gridColumnApi && !recalculado) {
            const allColumnIds: string[] = [];
            gridColumnApi.getColumns()!.forEach((column) => {
                allColumnIds.push(column.getId());
            });
            gridColumnApi.autoSizeColumns(allColumnIds, false);
            setRecalculado(true);
        }
    };

    useEffect(() => {
        if (gridApi) {
            gridApi.setRowData(props.dados);
        }
        recalcularColunas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dados, gridApi]);

    function onGridReady(params: AgGridEvent) {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        if (props.buscarDados) {
            props.buscarDados();
        } else {
            params.api.setRowData([]);
        }
    }

    const onSelectionChanged = () => {
        if (props.selectionChanged)
            props.selectionChanged(gridApi!.getSelectedRows());
    };

    return (
        <Box
            mt={1}
            mb={1}
            className={gridTheme}
            style={{
                height: '500px',
                width: '100%',
            }}
            flex={!props.flex ? undefined : '1'}
        >
            <AgGridReact
                frameworkComponents={{
                    customLoadingOverlay: customLoading,
                    customNoRows: customNoRows,
                }}
                loadingOverlayComponent={
                    props.buscarDados ? 'customLoadingOverlay' : null
                }
                noRowsOverlayComponent={'customNoRows'}
                onGridReady={onGridReady}
                pagination={true}
                paginationAutoPageSize={true}
                rowData={null}
                localeTextFunc={function (key: any, defaultValue: any) {
                    return textoGrid(key, defaultValue);
                }}
                onSelectionChanged={onSelectionChanged}
                columnDefs={props.colunas}
                {...props.grade}
            />
        </Box>
    );
};

export default Grade;
