import { createSlice } from '@reduxjs/toolkit';

const mensagemSlice = createSlice({
    name: 'mensagem',
    initialState: { mensagem: '' },
    reducers: {
        setMensagem: (state, action) => {
            return { mensagem: action.payload };
        },
        clearMensagem: (state) => {
            return { mensagem: '' };
        },
    },
});
export const { setMensagem, clearMensagem } = mensagemSlice.actions;

export default mensagemSlice.reducer;
