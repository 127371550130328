import { Heading } from '@chakra-ui/react';

const NotFound = () => {
    return (
        <div className="container">
            <header className="jumbotron">
                <Heading>NotFound</Heading>
            </header>
        </div>
    );
};

export default NotFound;
