import { Box, Heading } from '@chakra-ui/layout';

const NoRows = () => {
    return (
        <Box className="ag-custom-loading-cell">
            <Heading size="md">Nenhuma informação localizada.</Heading>
        </Box>
    );
};

export default NoRows;
