import { useState } from 'react';
import { Heading, Box, Tooltip } from '@chakra-ui/react';
import Button from '../../components/diversos/button';
import { msgErro } from '../../helpers/funcoes';
import axios from '../../helpers/axios';
import { useNavigate } from 'react-router';
import ConfirmarExclusao from '../../components/diversos/confirmarExclusao';
import Grade from '../../components/Grade';
import Base from '../../components/base';

const Recorrente = () => {
    let history = useNavigate();
    const [data, setData] = useState([]);
    const [cartaoSel, setCartaoSel] = useState({
        id: 0,
        numero: '',
        status: '',
    });
    const [dlgOpen, setDlgOpen] = useState(false);

    const cols = [
        { field: 'id', hide: true },
        { field: 'nome', headerName: 'Nome', minWidth: 250 },
        {
            field: 'numero',
            headerName: 'Número',
            minWidth: 200,
        },
        {
            field: 'status',
            headerName: 'Situação',
            minWidth: 150,
        },
    ];

    function buscarDados() {
        axios.get('/auth/recorrente').then((response) => {
            setData(response.data);
        });
    }

    const onSelectionChanged = (selectedRows: any) => {
        setCartaoSel(
            selectedRows.length === 1
                ? {
                      id: selectedRows[0].id,
                      numero: selectedRows[0].numero,
                      status: selectedRows[0].status,
                  }
                : { id: 0, numero: '', status: '' }
        );
    };

    function handleCancelar() {
        axios
            .delete(`/auth/recorrente/${cartaoSel.id}`)
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                let mensagem = '';
                if (error.response.data) mensagem = error.response.data.error;
                else mensagem = 'Um erro inesperado ocorreu na baixa.';
                msgErro(mensagem);
            });
    }

    return (
        <Base>
            <Box flex="1" display="flex" flexDir="column">
                <Box mb={2}>
                    <Heading size="md">
                        Pagamento Recorrente - Cartões Cadastrados
                    </Heading>
                </Box>
                <Box mb={2}>
                    <Tooltip
                        hasArrow
                        label="Exibe as parcelas pagas com o cartão selecionado"
                    >
                        <span>
                            <Button
                                mr={2}
                                minW="150px"
                                onClick={() => {
                                    history(
                                        `/recorrente/parcelas/${cartaoSel.id}/${cartaoSel.numero}`
                                    );
                                }}
                                isDisabled={cartaoSel.id === 0}
                            >
                                Parcelas
                            </Button>
                        </span>
                    </Tooltip>
                    <Tooltip
                        hasArrow
                        label="Cadastrar novo cartão para pagamento recorrente"
                    >
                        <span>
                            <Button
                                mr={2}
                                minW="150px"
                                onClick={() => {
                                    history('/recorrente/novo/');
                                }}
                            >
                                Novo Cartão
                            </Button>
                        </span>
                    </Tooltip>
                    <Tooltip
                        hasArrow
                        label="Cancela novas cobranças no cartão selecionado"
                    >
                        <span>
                            <Button
                                mr={2}
                                minW="150px"
                                isDisabled={
                                    cartaoSel.id === 0 ||
                                    cartaoSel.status === 'CANCELADO'
                                }
                                onClick={() => setDlgOpen(true)}
                                mt={{ lg: 0, xl: 0, base: 1 }}
                            >
                                Cancelar Cartão
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
                <Grade
                    dados={data}
                    colunas={cols}
                    buscarDados={buscarDados}
                    selectionChanged={onSelectionChanged}
                    grade={{ rowSelection: 'single' }}
                />
            </Box>
            <ConfirmarExclusao
                titulo="Confirmar"
                texto="Confirma o cancelamento dos recebimentos no cartão?"
                isOpen={dlgOpen}
                toggleOpen={setDlgOpen}
                apagar={handleCancelar}
            />
        </Base>
    );
};

export default Recorrente;
