import { useState } from 'react';
import { Heading, Box, Input, FormLabel, Text } from '@chakra-ui/react';
import Button from '../../components/diversos/button';
import { dataExibicao, formatarValor, msgErro } from '../../helpers/funcoes';
import axios from '../../helpers/axios';
import Grade from '../../components/Grade';
import Loading from '../../components/Loading';
import Base from '../../components/base';

const Extrato = () => {
    const [data, setData] = useState([]);
    const [item, setItem] = useState({ dataInicial: '', dataFinal: '' });
    const [loading, setLoading] = useState(false);
    const cols = [
        { field: 'referencia', headerName: 'Referência', maxWidth: 110 },
        {
            field: 'vencimento',
            headerName: 'Vencimento',
            valueFormatter: (params: any) =>
                dataExibicao(params.data.vencimento),
            minWidth: 110,
            maxWidth: 120,
        },
        {
            field: 'valor',
            type: 'numericColumn',
            headerName: 'Valor',
            valueFormatter: (params: any) => formatarValor(params.data.valor),
            maxWidth: 90,
        },
        {
            field: 'pagamento',
            headerName: 'Pagamento',
            valueFormatter: (params: any) =>
                dataExibicao(params.data.pagamento),
            minWidth: 110,
            maxWidth: 120,
        },
    ];

    function buscarDados() {
        setLoading(true);
        axios
            .get(
                `/auth/mensalidadepaga?datainicial=${item.dataInicial}&datafinal=${item.dataFinal}`
            )
            .then((response) => {
                setLoading(false);
                setData(response.data);
            })
            .catch((error) => {
                console.log('ERRO', error);
                msgErro(
                    'Não foi possível localizar as informações das mensalidades pagas.'
                );
                setLoading(false);
            });
    }

    function handleChange(evt: any) {
        let itemAux = item;
        itemAux = { ...itemAux, [evt.target.name]: evt.target.value };
        setItem(itemAux);
    }

    return (
        <Base>
            <Box flex="1" display="flex" flexDir="column">
                <Box mb={2}>
                    <Heading size="md">Mensalidades Pagas</Heading>
                </Box>
                <Box mb={2}>
                    <Box display="flex" flexDir="row">
                        <Box maxW="300px" mr={4}>
                            <FormLabel>Data Inicial</FormLabel>
                            <Input
                                type="date"
                                minW="150px"
                                maxW="200px"
                                name="dataInicial"
                                value={item.dataInicial}
                                onChange={(e) => handleChange(e)}
                            />
                        </Box>
                        <Box maxW="300px">
                            <FormLabel>Data Final</FormLabel>
                            <Input
                                type="date"
                                minW="150px"
                                maxW="200px"
                                name="dataFinal"
                                value={item.dataFinal}
                                onChange={(e) => handleChange(e)}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box mb={2}>
                    <Button
                        mr={2}
                        minW="150px"
                        onClick={buscarDados}
                        isDisabled={loading}
                    >
                        {loading && <Loading />}
                        <Text>Pesquisar</Text>
                    </Button>
                </Box>
                <Grade
                    dados={data}
                    colunas={cols}
                    buscarDados={buscarDados}
                    grade={{ rowSelection: 'single' }}
                    estilo={{
                        height: '500px',
                        width: '100%',
                    }}
                />
            </Box>
        </Base>
    );
};

export default Extrato;
