import {
    Box,
    FormLabel,
    Heading,
    Input,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import Button from '../../components/diversos/button';
import Loading from '../../components/Loading';
import axios from '../../helpers/axios';
import { msgErro, abrirPDF } from '../../helpers/funcoes';
import { isMobile } from 'react-device-detect';
import Base from '../../components/base';

const Declaracao = () => {
    const [ano, setAno] = useState('');
    const [loading, setLoading] = useState(false);
    const [erro, setErro] = useState('');
    function buscarDados() {
        setLoading(true);
        setErro('');
        let janela: any = null;
        if (!isMobile) janela = window.open('', '_blank');
        axios
            .post(`/auth/declaracao/${ano}`)
            .then(() => {
                axios(`/auth/declaracao/${ano}`, {
                    method: 'GET',
                    responseType: 'blob',
                })
                    .then((resposta) => {
                        abrirPDF(resposta, janela);
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                        setErro(
                            'Não foi possível realizar o download do PDF da declaração.'
                        );
                        msgErro(
                            'Não foi possível realizar o download do PDF da declaração.'
                        );
                    });
            })
            .catch((error) => {
                if (!isMobile) janela.close();
                setLoading(false);
                let mensagem = '';
                if (error.response.data) mensagem = error.response.data.error;
                else
                    mensagem =
                        'Não foi possível realizar o download do PDF da declaração.';
                setErro(mensagem);
                msgErro(mensagem);
            });
    }

    function handleChange(evt: any) {
        setAno(evt.target.value);
    }
    const corErro = useColorModeValue('red', 'white');

    return (
        <Base>
            <Box flex="1" display="flex" flexDir="column">
                <Box mb={2}>
                    <Heading size="md">Declaração de Quitação</Heading>
                </Box>
                <Box mb={2}>
                    <FormLabel>Ano</FormLabel>
                    <Input
                        name="ano"
                        maxLength={4}
                        maxW="100px"
                        value={ano}
                        onChange={(e) => handleChange(e)}
                    />
                </Box>
                <Box mb={2}>
                    <Button onClick={buscarDados} isDisabled={loading}>
                        {loading && <Loading />}
                        <span>Pesquisar</span>
                    </Button>
                </Box>
                {erro !== '' && (
                    <Box mb={1}>
                        <Text color={corErro} fontWeight="bold">
                            {erro}
                        </Text>
                    </Box>
                )}
            </Box>
        </Base>
    );
};

export default Declaracao;
